<template>
  <i class="el-icon-refresh" @click="refreshRoute"></i>
</template>

<script>
  export default {
    name: 'bdRefresh',
    methods: {
      async refreshRoute() {
        this.$baseEventBus.$emit('refresh-router-page')
      },
    },
  }
</script>
<template>
  <!--常规布局 -->
  <div class="bd-layout-container-common fixed">
    <div class="bd-layout-header fixed-header">
      <top-bar></top-bar>
      <div>
        <side-bar />
        <div
          class="vab-main"
          :class="{'is-collapse-main': collapse,}"
        >
          <tabs-page />
        </div>
      </div>
    </div>
    <div 
      class="vab-main"
      :class="{'is-collapse-main': collapse}"
    >
      <!-- <breadcrumb-page class="hidden-xs-only" /> -->
    </div>
    <div 
      class="vab-main main-padding"
      :class="{'is-collapse-main': collapse}"
    >
      <app-main />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BdLayoutCommonSys',
  props: {
    collapse: {
      type: Boolean,
      default () {
        return false
      }
    },
    device: {
      type: String,
      default () {
        return 'desktop'
      }
    }
  },
  computed: {
    sysPage () {
      const route = this.$route
      const { matched } = route
      if (matched[0] && matched[0].meta.title === '系统管理') {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
  // @mixin container-common {
  //   background:#282c34 !important;
  // }

  // @mixin active {
  //   &:hover {
  //     color: $base-color-white !important;
  //     background-color: $base-color-blue !important;
  //   }

  //   &.is-active {
  //     color: $base-color-white !important;
  //     background-color: $base-color-blue !important;
  //   }
  // }

  .bd-layout-container-common {
    // .vab-main {
    //   .fixed-header {
    //     left: $base-left-menu-width;
    //     width: $base-right-content-width;
    //   }
    // }

    // .vab-main {
    //   margin-left: 0;
    // }

    ::v-deep {
      // .vab-tabs-content {
      //   width: calc(
      //     100% - 60px - #{$base-font-size-default} - #{$base-padding} - 2px
      //   ) !important;
      // }

      .top-bar-container {
        .vab-main {
          width: 100%;
          margin: auto $base-margin;
        }
      }
    }

    // ::v-deep .el-menu {
    //     @include container-common;

    //     .el-submenu__title {
    //       @include container-common;
    //     }

    //     .el-menu-item {
    //       @include container-common;
    //     }
    //   }

    //   .vab-side-bar {
    //     @include container-common;

    //     .el-menu-item {
    //       @include active;
    //     }
    //   }

  }
</style>

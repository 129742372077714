import { tokenTableName } from '@/config'
import cookie from 'js-cookie'

/**
 * @description 获取token
 * @returns {}
 */
 export function getToken() {
  return cookie.get(tokenTableName)
}

/**
 * @description 存储token
 * @param token
 * @returns
 */
 export function setToken(token) {
 removeToken()
  return cookie.set(tokenTableName, token)
}

/**
 * @description 移除token
 * @returns
 */
 export function removeToken() {
  return cookie.remove(tokenTableName)
}

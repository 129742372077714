<!--
@name:富文本编辑器封装
@orthor:焦建波
@date:2022/4/14
-->
<template>
  <editor id="tinymce" v-model="content" tag-name="div" :init="init"/>
</template>

<script>
  import tinymce from "tinymce/tinymce";
  import Editor from "@tinymce/tinymce-vue";
  import "tinymce/themes/silver/theme"; // 引用主题文件
  import "tinymce/icons/default"; // 引用图标文件
  import "tinymce/plugins/link";
  import "tinymce/plugins/code";
  import "tinymce/plugins/table";
  import "tinymce/plugins/lists";
  import "tinymce/plugins/advlist";
  import "tinymce/plugins/anchor";
  import "tinymce/plugins/autolink"; //锚点
  import "tinymce/plugins/autoresize";
  import "tinymce/plugins/autosave";
  import "tinymce/plugins/charmap"; //特殊字符
  import "tinymce/plugins/code"; //查看源码
  import "tinymce/plugins/codesample"; //插入代码
  import "tinymce/plugins/directionality"; //
  import "tinymce/plugins/fullpage"; //页面属性编辑
  import "tinymce/plugins/fullscreen"; //全屏
  import "tinymce/plugins/help"; //帮助
  import "tinymce/plugins/hr"; //横线
  import "tinymce/plugins/image"; //图片
  import "tinymce/plugins/imagetools"; //图片工具
  import "tinymce/plugins/importcss"; //图片工具
  import "tinymce/plugins/insertdatetime"; //时间插入
  import "tinymce/plugins/media"; //媒体插入
  import "tinymce/plugins/nonbreaking"; //
  import "tinymce/plugins/noneditable"; //不间断空格
  import "tinymce/plugins/pagebreak"; //分页
  import "tinymce/plugins/paste"; //粘贴
  import "tinymce/plugins/preview"; //预览
  import "tinymce/plugins/quickbars"; //快捷菜单
  import "tinymce/plugins/searchreplace"; //查询替换
  import "tinymce/plugins/template"; //插入模板
  import "tinymce/plugins/visualblocks"; //
  import "tinymce/plugins/visualchars"; //
  import "tinymce/plugins/wordcount"; //数字统计
  // import '../../../public/static/tinymce/skins/ui/oxide/skin.css'
  // import '../../../public/static/tinymce/skins/zh_CN'
  // import 'tinymce/skins/ui/oxide/skin.css'
  import 'tinymce/themes/silver/theme'
  import {getUploadImage} from '@/api/fileUpload'

  export default {
    name: "tinymceEditor",
    components: {
      editor: Editor,
    },
    props: {
      modelValue: {
        type: String,
        default: ''
      },
      homeContents: {
        type: String,
        default: ''
      },
      minheigth: Number,
      maxheight: Number
    },
    data() {
      return {
        content: this.modelValue,
        init: {
          language: "zh_CN",
          language_url: `static/tinymce/skins/zh_CN.js`,  // 中文语言包路径
          placeholder: this.homeContents || '请再次输入内容...',
          skin_url: `static/tinymce/skins/ui/oxide`, // 编辑器皮肤样式
          menubar: '', // 隐藏菜单栏
          max_height: this.maxheight,//最大高度
          min_height: this.minheigth,//最小高度
          //   height: 320,
          toolbar_mode: "none",//工具栏的模式
          plugins: ['advlist anchor autolink autoresize autosave charmap code codesample directionality emoticons fullpage fullscreen help hr image imagetools insertdatetime link lists media nonbreaking noneditable pagebreak paste preview print save searchreplace spellchecker tabfocus table template textpattern visualblocks visualchars wordcount image'], // 插件需要import进来
          toolbar: ['fontsizeselect fontselect bold italic underline strikethrough alignleft aligncenter alignright outdent indent blockquote undo redo removeformat subscript superscript  hr bullist numlist link charmap table emoticons forecolor backcolor preview image'],
          content_style: "img { max-width: 100%; display: inline-block; height: auto; }",
          inline_boundaries_selector: 'img',
          // content_style: `
          //   *                         { padding:0; margin:0; }
          //   html, body                { height:100%; }
          //   body                      { padding: 15px; box-sizing: border-box; }
          //   img                       { max-width:100%; display:inline-block;height:auto; }
          //   a                         { text-decoration: none; }
          //   iframe                    { width: 100%; }
          //   p                         { line-height:1.6; margin: 0px; word-break: break-all; word-wrap: break-word; }
          //   table                     { word-wrap:break-word; word-break:break-all; max-width:100%; border:none; border-color:#999; }
          //   .mce-object-iframe        { width:100%; box-sizing:border-box; margin:0; padding:0; }
          //   ul,ol                     { list-style-position:inside; }
          // `,
          fontsize_formats: "12px 14px 16px 18px 24px 36px 48px 56px 72px",//字体下拉选择
          font_formats: "苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;",//字体下拉选择

          branding: false,//不显示有tiny驱动
          link_default_target: '_blank',//打开链接都是以新开标签页的形式
          contextmenu: false,//编辑区域鼠标无法右键
          paste_data_images: true,//开启复制粘贴以及拖动桌面图片图片上传
          elementpath: false, //禁用下角的当前标签路径
          resize: false, // 禁止改变大小
          statusbar: false, // 隐藏底部状态栏
          //   图片上传
          images_upload_handler: function (blobInfo, success) {
            let fileInfo = blobInfo.blob()
            let formData = new FormData();
            formData.append("file", fileInfo);
            let upload = async () => {
              const {data} = await getUploadImage(formData)
              if(data.code === 200){
                success(data.url);
              }
            }
            // let reader = new FileReader();
            // reader.readAsDataURL(blobInfo.blob());
            // reader.onload = function () {
            //   success(this.result);
            // };
            upload()
          },
        }
      }
    },

    emits: {"update:modelValue": null},
    computed: {},

    /**
     *
     *  监控tinymce中的数据变化"
     *
     * */
    watch: {
      // modelValue(newValue){
      //   console.log("这是初始化的数据",newValue)
      //   this.content = newValue
      // },
      modelValue: {
        handler(val) {
          this.content = val
        },
        immediate: true,
        deep: true
      },
      content(newValue) {
        this.$emit('input', newValue)
      },

    },
    created() {
      this.$on('change', (content) => {
        tinymce.activeEditor.setContent(content);
      });
    },

    methods: {
      addcontent(val) {
        this.content = val
      }
    },
    beforeDestroy() {
      this.$off('change');
      tinymce.activeEditor.destroy();
    },

    /**
     *
     * 生命周期 - 挂载完成（可以访问DOM元素）",html模板已渲染
     * 初始化tinymce
     *
     */
    mounted() {
      tinymce.init({})
    },
  }
</script>

<style scoped>
</style>

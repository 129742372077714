import Vue from 'vue'
import axios from 'axios'
import router from '@/router'
import {baseURL, requestTimeout, contentType, debounce, tokenName} from '@/config'
import {getToken, removeToken, setToken} from '@/utils/token'
import store from '@/store'
import qs from 'qs'
import {JSEncrypt} from "jsencrypt";

let publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCoY1wD2BAb+CmaTGoxBD0CwrrWsRVxifmeLTz/WKbG/3Kt2TEDB6duG1G5P06UnS7VAE3FSeMzM18cGOhJtAItkaI8DM7QqsO5dqPdJ14ulIebziQ3ZqgqCWDw50jt778K9jyPpHgY0Z0hMwdm4iBiYRDKPBx7Ci+aJkiie3vehQIDAQAB" //公钥
let privateKey = "MIICdwIBADANBgkqhkiG9w0BAQEFAASCAmEwggJdAgEAAoGBAKhjXAPYEBv4KZpMajEEPQLCutaxFXGJ+Z4tPP9Ypsb/cq3ZMQMHp24bUbk/TpSdLtUATcVJ4zMzXxwY6Em0Ai2RojwMztCqw7l2o90nXi6Uh5vOJDdmqCoJYPDnSO3vvwr2PI+keBjRnSEzB2biIGJhEMo8HHsKL5omSKJ7e96FAgMBAAECgYAukINigL47rc17YMRA18YzXDwkoIWohxojFql2h0JfZ0aEMrpt9xDizuUzkAEf8bOArXvUIdGVWBBmCbE6qG+tTWpHjW4p6q5uhE5C2eyBJqDjqVwBPLV8dHokdYpG9Lkds6iFsFW8tXkg1XlAV3QvdyEEBxurRBEgLwQA9TacBQJBANTAI8Dmb8wHnZFi2t+rLcjWQ0hxuF9FuH1ZiHQ8jTadYfjG4LXUgZgJhe5NG9MuZiDk7ReiuThEmfLUt4njK1sCQQDKnog7jd7CrR5p4hw58yzS1vyWtZTQkQ5XvcWKz75Ksh4cAqrAxFHAc3daOc734nn6o0tdEUOYtA/6NnxHq6OfAkEAzFU+AadQ+MTXsSsgzHG8J4iiVpHiYp6z8rBrPjhp6GqADk/0wq63SS7cU2P7tXh63l0A2hKPkltRAMkM/oIQwQJAXDpTGoCWQBja+ilQ3dPhuErhiJw4k8MjOy8HZwruvENdTzSrkrkJ2Ub1sd0UZ2LwUPTZThCYqz5y81qvsVPgUwJBAMooAQGxDMo6spWrUbT6L3adc1iD1f5ra9fTr/5UnsuLf1ArowtzhHkE14q3vnQiwX/oB+pE+DJ16PiuQ6jo/k4="// 私钥

const CancelToken = axios.CancelToken
// import router from '@/router'

let loadingInstance

const CODE_MESSAGE = {
  0: '未可知错误，可能是因为后端不支持跨域CORS、接口地址不存在等问题引起',
  1006: '令牌过期'
}
const peding = {}
/**
 * 重复请求拦截
 */
const remoPending = (key, isRequest = false) => {
  if (peding[key] && isRequest) {
    peding[key]('取消重复请求')
  }
  delete peding[key]
}
/**
 * @description axios数据请求拦截器配置
 */
const requestConf = (config) => {
  let key = config.url + '&' + config.method + '&' + JSON.stringify(config.data)

  const token = getToken(tokenName)

  if (token) config.headers.token = `${token}`
  let menupath = window.localStorage.getItem('meuPath')
  if (config.url.indexOf("https://portal") === -1) {
    config.headers.menuId = encryptedData(menupath + ':' + token)
  }


  if (config.data && config.headers['Content-Type'] === 'application/x-www-form-urlencoded;charset=UTF-8') {
    config.data = qs.stringify(config.data)
  }

  if (config.url === "https://portal.shaanxieco.com/portal/sharing/rest/generateToken") {
    //从arcgis server获取arcgis Token
    config.headers = {
      'Content-type': 'application/x-www-form-urlencoded',
    }
    config.transformRequest = [
      (data) => {
        let ret = ''
        for (let it in data) {
          ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
        }

        return ret
      }
    ]
  }
  if (debounce.some((item) => config.url.includes(item))) {
    loadingInstance = Vue.prototype.$baseLoading(1)
  }
  remoPending(key, true)
  config.cancelToken = new CancelToken((c) => {
    peding[key] = c
  })
  return config
}
//对密码进行加密传输
let encryptedData = (data) => {
  let encryptor = new JSEncrypt();
  //设置公钥
  encryptor.setPublicKey(publicKey);
  return encryptor.encrypt(data);
}

/**
 * @description axios数据响应拦截器配置
 * @param config 请求配置
 * @param data response数据
 * @param status HTTP status
 * @param statusText HTTP status text
 * @returns {Promise<*|*>}
 */
const handleData = async (res) => {
  // { config, data, status = 0, statusText }
  let key = res.config.url + '&' + res.config.method + '&' + JSON.stringify(res.config.data)
  remoPending(key)
  if (loadingInstance) loadingInstance.close()
  let code = res.data.code

  switch (code) {
    case 0:
      return res
    case 200:
      return res
    case 500:
      return res
    case 1006:
      store.dispatch('user/resetAll')
        .then(() => {
            const errorMessage = `${
              res.data && res.data.msg ? res.data.msg : CODE_MESSAGE[code] ? CODE_MESSAGE[code] : statusText
            }`
          if( window.localStorage.getItem("messageFlag") === '0'){
            Vue.prototype.$baseMessage(errorMessage, 'error')
            console.log("1006错误",errorMessage)
            window.localStorage.setItem('messageFlag','1')
          }
          return router.push({path: '/login', replace: true}).then(() => {
            })
          }
        )
      break
    default:
      return res
  }
  // 异常处理
  // const errorMessage = `${
  //   data && data.msg ? data.msg : CODE_MESSAGE[code] ? CODE_MESSAGE[code] : statusText
  // }`
  // Vue.prototype.$baseMessage(errorMessage, 'error')
}

/**
 * @description axios数据接口请求初始化
 */
const instance = axios.create({
  baseURL,
  timeout: requestTimeout,
  headers: {
    'Content-Type': contentType
  }
})

/**
 * @description axios数据请求拦截器
 */
instance.interceptors.request.use(requestConf, (error) => {
  return Promise.reject(error)
})

/**
 * @description axios响应拦截器
 * @param {*} config
 * @returns
 */
instance.interceptors.response.use(
  (response) => handleData(response),
  (error) => {
    const {response = {}} = error
    return handleData(response)
    // return response
  }
)
export default instance

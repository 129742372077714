/**
 * @description 整个系统的全局配置状态管理，请勿随意修改
 */
import { isJson } from '@/utils/validate'
import {
  collapse as _collapse,
  showProgressBar,
  title,
  layout,
  fixedHeader,
  showTheme
} from '@/config'

const defaultTheme = { showProgressBar, layout, showTheme, fixedHeader }

const getLocalStorage = (key) => {
  const value = localStorage.getItem(key)
  if (isJson(value)) {
    return JSON.parse(value)
  } else {
    return false
  }
}
const { collapse } = getLocalStorage('collapse')

const state = () => ({
  theme: { ...defaultTheme },
  title,
  device: 'desktop',
  collapse: collapse || _collapse
})

const getters = {
  theme: (state) => state.theme,
  title: (state) => state.title,
  device: (state) => state.device,
  collapse: (state) => state.collapse
}

const mutations = {
  openSideBar(state) {
    state.collapse = false
  },
  foldSideBar(state) {
    state.collapse = true
  },
  toggleDevice (state, device) {
    state.device = device
  },
  toggleCollapse(state) {
    state.collapse = !state.collapse
    localStorage.setItem('collapse', `{"collapse":${state.collapse}}`)
  },
}

const actions = {
  openSideBar({ commit }) {
    commit('openSideBar')
  },
  foldSideBar({ commit }) {
    commit('foldSideBar')
  },
  toggleDevice ({ commit }, device) {
    commit('toggleDevice', device)
  },
  toggleCollapse({ commit }) {
    commit('toggleCollapse')
  },
}

export default { state, getters, mutations, actions }

<template>
    <div class="top-bar-container">
        <div class="vab-main">
            <el-row :gutter="20">
                <el-col :xl="19" :lg="19" :md="19" :sm="19" :xs="19">
                  <div class="left-panel">
                    <logo-page />
                    <el-menu
                      :active-text-color="variables['menu-main-color-active']"
                      :background-color="variables['menu-background']"
                      :default-active="activeMenu"
                      menu-trigger="hover"
                      :unique-opened="true"
                      mode="horizontal"
                      :text-color="variables['menu-color']"
                    >
                      <template v-for="(route, index) in handleRoutes">
                        <bd-menu
                          v-if="route.meta && !route.meta.hidden"
                          :key="index + route.name"
                          :item="route"
                          :layout="layout"
                        />
                        <el-menu-item
                          v-if="route.meta && route.meta.title === '系统管理'"
                          :key='route.path'
                          :index='route.path'
                          @click="handleLink(route.path)"
                        >
                        <svg-icon
                          v-if="route.meta && route.meta.icon"
                          :icon-class="route.meta.icon"
                          :is-custom-svg="route.meta.isCustomSvg"
                        />
                          {{route.meta.title}}
                        </el-menu-item>
                      </template>
                    </el-menu>
                  </div>
                </el-col>
                <el-col :xl="5" :lg="5" :md="5" :sm="5" :xs="5">
                  <div class="right-panel">
                    <badge-information />
                    <!-- 大屏 -->
                    <bd-visual-screen />
                    <!-- 刷新 -->
                    <bd-refresh />
                    <bd-avatar />
                  </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>

import variables from '@/bd/styles/variables/variables.module.scss'
import { mapGetters } from 'vuex'
import BadgeInformation from "@/bd/components/BadgeInformation";

export default {
  name: 'TopBar',
  components: {BadgeInformation},
  props: {
    layout: {
      type: String,
      default: 'horizontal'
    }
  },
  data () {
    return {
      pulse: false,
      menuTrigger: 'hover'
    }
  },
  computed: {
    ...mapGetters({
      routes: 'routes/routes',
      visitedRoutes: 'tabsBar/visitedRoutes'
    }),
    activeMenu () {
      const route = this.$route
      const { meta, path, matched } = route
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      if (matched[0] && matched[0].meta.title === '系统管理') {
        return matched[0].path
      }
      return path
    },
    variables () {
      return variables
    },
    handleRoutes () {
      return this.routes.flatMap((route) => {
        return route.meta && route.meta.levelHidden === true && route.children
          ? route.children
          : route
      })
    }
  },
  // mounted () {
  //   console.log(variables)
  // },
  methods: {
    async refreshRoute () {
      this.$baseEventBus.$emit('reload-router-view')
      this.pulse = true
      setTimeout(() => {
        this.pulse = false
      }, 1000)
    },
    handleLink (path) {
      this.$router.push(path)
    }
  }
}
</script>
<style lang="scss" scoped>

.top-bar-container {
    display: flex;
    align-items: center;
    // justify-items: flex-end;
    justify-content: flex-start;
    height: $base-top-bar-height;
    background: $base-menu-background;

  .vab-main {
      padding: 0 $base-padding 0 $base-padding;

    .left-panel {
      display: flex;
      align-items: center;
      // justify-content: flex-end;
      height: $base-top-bar-height;

      ::v-deep {
        > .el-menu--horizontal.el-menu
          > .el-submenu
          > .el-submenu__title
          > .el-submenu__icon-arrow {
          float: right;
          margin-top: #{math.div($base-top-bar-height - 11, 2)} !important;
        }

        > .el-menu--horizontal.el-menu > .el-menu-item {
          .el-tag {
            margin-top: #{math.div($base-top-bar-height, 2)- 7.5} !important;
            margin-left: 5px;
          }

          .vab-dot {
            float: right;
            margin-top: #{math.div($base-top-bar-height - 6, 2) + 1};
          }

          @media only screen and (max-width: 1199px) {
            .el-tag {
              display: none;
            }
          }
        }

        .el-menu {
          &.el-menu--horizontal {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: $base-top-bar-height;
            border-bottom: 0 solid transparent !important;

            .el-menu-item,
            .el-submenu__title {
              height: #{math.div($base-top-bar-height, 1.3)};
              padding: 0 $base-padding;
              line-height: #{math.div($base-top-bar-height, 1.3)};
            }

            > .el-menu-item,
            > .fixIcon > .el-submenu {
              height: $base-top-bar-height;
              line-height: $base-top-bar-height;

              .el-submenu__icon-arrow {
                float: right;
                margin-right: -17px;
                // margin-top: #{math.div($base-menu-item-height - 16, 2)};
              }

              > .el-submenu__title {
                // display: flex;
                // align-items: flex-start;
                height: $base-top-bar-height;
                line-height: $base-top-bar-height;
              }
            }
          }

          [class*='ri-'] {
            margin-left: 0;
            color: rgba($base-color-white, 0.9);
            cursor: pointer;
          }

          .el-submenu,
          .el-menu-item {
            i {
              color: inherit;
            }

            &.is-active {
              border-bottom: 0 solid transparent;

              .el-submenu__title {
                border-bottom: 0 solid transparent;
              }
            }
          }

          .el-menu-item {
            &.is-active {
              // background: $base-color-blue !important;
            }
          }
        }
      }
    }

    .right-panel {
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: flex-end;
      height: $base-nav-height;

      ::v-deep {
        [class*='el-icon-'] {
          margin-left: $base-margin;
          color: $base-color-white;
          font-size: 16px;
          cursor: pointer;
        }

        button {
          [class*='el-icon-'] {
            margin-left: 0;
            font-size: 16px;
            color: $base-color-white;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>

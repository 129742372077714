const state = {
  view: null // Create the MapView
}

const getters = {
  view: (state) => state.view
}

const mutations = {
  changeView: (state, view) => {
    if (view) state.view = view
  }
}

const actions = {
  changeView ({ commit }, view) {
    commit('changeView', view)
  }
}

export default { state, getters, mutations, actions }

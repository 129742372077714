/*
 * @Author:
 * @Date: 2022-05-05 09:59:39
 * @LastEditors:
 * @LastEditTime: 2022-05-17 14:25:16
 * @FilePath: \BigData_Upgrades\src\api\upload.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'
/**
 * 图片上传接口【post】
 */
 export function imgUpload (data, url) {
  return request({
    url: (url == '' || url == undefined) ? '/zuul/cloudstorage/fileUpload' : url,
    method: 'post',
    // responseType: 'blob',
    // ContentType: 'multipart/form-data',
    data
  })
};

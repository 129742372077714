<template>
  <input
    type="text"
    :class="disabled?'input-v-model-dis':'input-v-model'"
    :value="value"
    @input="inputChange"
    :disabled="disabled"
    placeholder="请输入..."
  />
</template>

<script>
export default {
  name: "inputArea",
  props: ['value', 'disabled'],
  methods: {
    // $emit 方法可以触发当前实例上的事件，这里触发的事input事件，附加参数都会传给监听器回调
    // input 事件在用户输入时触发，它是在元素值发生变化时立即触发
    inputChange (e) {
      let val = e.target.value.toString()
      val = val.replace(/[^\d.]/g, ""); // 清除"数字"和"."以外的字符
      val = val.replace(/^\./g, ""); // 验证第一个字符是数字
      val = val.replace(/^0+([1-9]\d*)/, '$1'); // 验证数字是否为不以0开头，并且是正数
      val = val.replace(/\.{2,}/g, "."); // 只保留第一个, 清除多余的
      val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      val = val.replace(/^(\-)*(\d+)\.(\d{4}).*$/, '$1$2.$3'); // 只能输入四个小数
      val = val.indexOf('00') === 0 ? '0' : val
      let ss = val.split('.')
      if (ss.length === 2) {
        val = ss[0].slice(0, 9).toString() + "." + ss[1].toString() // 只能输入两个小数
      } else {
        val = ss[0].slice(0, 9).toString()
      }
      e.target.value = val;
      this.$emit("input", e.target.value);
    }
  }
}
</script>

<style scoped>
.input-v-model {
  width: 100%;
  height: 30px;
  border: none;
  color: #606266;
}
.input-v-model-dis {
  /* color: #C0C4CC; */
  color: #606266;
  width: 100%;
  height: 30px;
  border: none;
  background-color: #f6f8f9;
  cursor: no-drop;
}
</style>

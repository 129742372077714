<!--  -->
<template>
  <i 
    class="coll-fold" 
    :class="collapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'" 
    :style="classObject"
    @click="toggleCollapse"
  />
  <!-- <svg-icon
    class="fold-unfold"
    :iconClass="collapse ? 'menu-unfold-line' : 'menu-fold-line'"
    :style="classObject"
    @click="toggleCollapse"
  /> -->
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Collapse',
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      theme: 'settings/theme',
      collapse: 'settings/collapse',
    }),
    classObject() {
      return {
        display:
          this.$route.meta.noColumn
            ? 'none'
            : '',
      }
    },
  },
  watch: {
    classObject: {
      handler({ display }) {
        if (display === 'none') this.foldSideBar()
        else this.openSideBar()
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      openSideBar: 'settings/openSideBar',
      foldSideBar: 'settings/foldSideBar',
      toggleCollapse: 'settings/toggleCollapse',
    }),
  },
}
</script>
<style lang="scss" scoped>
  .coll-fold {
    color: $base-color-grey;
    cursor: pointer;
  }
</style>
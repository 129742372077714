const provinceObj = {
  "msg": "success",
  "code": 0,
  "data": [
    // [
    //   "610000",
    //   "1281521941815042049"
    // ],
    // [
    //   "610100",
    //   "1281521941815042050"
    // ],
    // [
    //   "610102",
    //   "1281521941815042051"
    // ],
    // [
    //   "610103",
    //   "1281521941970231297"
    // ],
    // [
    //   "610104",
    //   "1281521942062505986"
    // ],
    // [
    //   "610111",
    //   "1281521942167363585"
    // ],
    // [
    //   "610112",
    //   "1281521942263832577"
    // ],
    // [
    //   "610113",
    //   "1281521942389661698"
    // ],
    // [
    //   "610114",
    //   "1281521942507102209"
    // ],
    // [
    //   "610115",
    //   "1281521942599376898"
    // ],
    // [
    //   "610122",
    //   "1281521942725206017"
    // ],
    // [
    //   "610124",
    //   "1281521942838452225"
    // ],
    // [
    //   "610118",
    //   "1281521942939115521"
    // ],
    // [
    //   "610117",
    //   "1281521943064944642"
    // ],
    // [
    //   "610200",
    //   "1281521943140442113"
    // ],
    // [
    //   "610202",
    //   "1281521943140442114"
    // ],
    // [
    //   "610203",
    //   "1281521943253688321"
    // ],
    // [
    //   "610204",
    //   "1281521943354351618"
    // ],
    // [
    //   "610222",
    //   "1281521943459209217"
    // ],
    // [
    //   "610300",
    //   "1281521943534706690"
    // ],
    // [
    //   "610302",
    //   "1281521943534706691"
    // ],
    // [
    //   "610303",
    //   "1281521943656341506"
    // ],
    // [
    //   "610304",
    //   "1281521943757004801"
    // ],
    // [
    //   "610322",
    //   "1281521943882833922"
    // ],
    // [
    //   "610323",
    //   "1281521943979302914"
    // ],
    // [
    //   "610324",
    //   "1281521944084160513"
    // ],
    // [
    //   "610326",
    //   "1281521944205795330"
    // ],
    // [
    //   "610327",
    //   "1281521944298070017"
    // ],
    // [
    //   "610328",
    //   "1281521944402927618"
    // ],
    // [
    //   "610329",
    //   "1281521944511979522"
    // ],
    // [
    //   "610330",
    //   "1281521944612642818"
    // ],
    // [
    //   "610331",
    //   "1281521944713306114"
    // ],
    // [
    //   "610400",
    //   "1281521944780414977"
    // ],
    // [
    //   "610402",
    //   "1281521944780414978"
    // ],
    // [
    //   "610403",
    //   "1281521944906244097"
    // ],
    // [
    //   "610404",
    //   "1281521945002713089"
    // ],
    // [
    //   "610422",
    //   "1281521945120153601"
    // ],
    // [
    //   "610423",
    //   "1281521945237594114"
    // ],
    // [
    //   "610424",
    //   "1281521945321480194"
    // ],
    // [
    //   "610425",
    //   "1281521945409560577"
    // ],
    // [
    //   "610426",
    //   "1281521945514418177"
    // ],
    // [
    //   "610482",
    //   "1281521945619275778"
    // ],
    // [
    //   "610428",
    //   "1281521945715744770"
    // ],
    // [
    //   "610429",
    //   "1281521945820602369"
    // ],
    // [
    //   "610430",
    //   "1281521945917071361"
    // ],
    // [
    //   "610431",
    //   "1281521946013540353"
    // ],
    // [
    //   "610481",
    //   "1281521946105815041"
    // ],
    // [
    //   "610500",
    //   "1281521946181312514"
    // ],
    // [
    //   "610502",
    //   "1281521946181312515"
    // ],
    // [
    //   "610503",
    //   "1281521946319724546"
    // ],
    // [
    //   "610522",
    //   "1281521946424582146"
    // ],
    // [
    //   "610523",
    //   "1281521946533634050"
    // ],
    // [
    //   "610524",
    //   "1281521946630103042"
    // ],
    // [
    //   "610525",
    //   "1281521946734960642"
    // ],
    // [
    //   "610526",
    //   "1281521946839818242"
    // ],
    // [
    //   "610527",
    //   "1281521946944675842"
    // ],
    // [
    //   "610528",
    //   "1281521947045339138"
    // ],
    // [
    //   "610581",
    //   "1281521947141808129"
    // ],
    // [
    //   "610582",
    //   "1281521947255054337"
    // ],
    // [
    //   "610600",
    //   "1281521947347329026"
    // ],
    // [
    //   "610602",
    //   "1281521947347329027"
    // ],
    // [
    //   "610621",
    //   "1281521947481546754"
    // ],
    // [
    //   "610622",
    //   "1281521947582210049"
    // ],
    // [
    //   "610623",
    //   "1281521947674484737"
    // ],
    // [
    //   "610603",
    //   "1281521947779342338"
    // ],
    // [
    //   "610625",
    //   "1281521947880005634"
    // ],
    // [
    //   "610626",
    //   "1281521947984863233"
    // ],
    // [
    //   "610627",
    //   "1281521948081332226"
    // ],
    // [
    //   "610628",
    //   "1281521948186189826"
    // ],
    // [
    //   "610629",
    //   "1281521948274270209"
    // ],
    // [
    //   "610630",
    //   "1281521948374933505"
    // ],
    // [
    //   "610631",
    //   "1281521948492374018"
    // ],
    // [
    //   "610632",
    //   "1281521948593037313"
    // ],
    // [
    //   "610700",
    //   "1281521948664340481"
    // ],
    // [
    //   "610702",
    //   "1281521948664340482"
    // ],
    // [
    //   "610703",
    //   "1281521948777586690"
    // ],
    // [
    //   "610722",
    //   "1281521948882444290"
    // ],
    // [
    //   "610723",
    //   "1281521948983107585"
    // ],
    // [
    //   "610724",
    //   "1281521949075382274"
    // ],
    // [
    //   "610725",
    //   "1281521949180239874"
    // ],
    // [
    //   "610726",
    //   "1281521949285097474"
    // ],
    // [
    //   "610727",
    //   "1281521949381566466"
    // ],
    // [
    //   "610728",
    //   "1281521949482229761"
    // ],
    // [
    //   "610729",
    //   "1281521949599670273"
    // ],
    // [
    //   "610730",
    //   "1281521949704527873"
    // ],
    // [
    //   "610800",
    //   "1281521949767442433"
    // ],
    // [
    //   "610802",
    //   "1281521949767442434"
    // ],
    // [
    //   "610881",
    //   "1281521949884882946"
    // ],
    // [
    //   "610822",
    //   "1281521949985546242"
    // ],
    // [
    //   "610803",
    //   "1281521950082015234"
    // ],
    // [
    //   "610824",
    //   "1281521950178484225"
    // ],
    // [
    //   "610825",
    //   "1281521950274953217"
    // ],
    // [
    //   "610826",
    //   "1281521950375616513"
    // ],
    // [
    //   "610827",
    //   "1281521950472085505"
    // ],
    // [
    //   "610828",
    //   "1281521950572748802"
    // ],
    // [
    //   "610829",
    //   "1281521950694383618"
    // ],
    // [
    //   "610830",
    //   "1281521950790852609"
    // ],
    // [
    //   "610831",
    //   "1281521950887321601"
    // ],
    // [
    //   "610900",
    //   "1281521950962819073"
    // ],
    // [
    //   "610902",
    //   "1281521950962819074"
    // ],
    // [
    //   "610921",
    //   "1281521951080259586"
    // ],
    // [
    //   "610922",
    //   "1281521951185117186"
    // ],
    // [
    //   "610923",
    //   "1281521951281586178"
    // ],
    // [
    //   "610924",
    //   "1281521951382249473"
    // ],
    // [
    //   "610925",
    //   "1281521951474524162"
    // ],
    // [
    //   "610926",
    //   "1281521951570993154"
    // ],
    // [
    //   "610927",
    //   "1281521951688433665"
    // ],
    // [
    //   "610928",
    //   "1281521951801679874"
    // ],
    // [
    //   "610929",
    //   "1281521951910731777"
    // ],
    // [
    //   "611000",
    //   "1281521952003006466"
    // ],
    // [
    //   "611002",
    //   "1281521952003006467"
    // ],
    // [
    //   "611021",
    //   "1281521952128835586"
    // ],
    // [
    //   "611022",
    //   "1281521952246276098"
    // ],
    // [
    //   "611023",
    //   "1281521952346939393"
    // ],
    // [
    //   "611024",
    //   "1281521952468574210"
    // ],
    // [
    //   "611025",
    //   "1281521952560848897"
    // ],
    // [
    //   "611026",
    //   "1281521952669900801"
    // ],
    // [
    //   "610116",
    //   "1281521943064944643"
    // ]
      ['610000', '1281521941815042049'],
      ['610100', '1281521941815042050'],
      ['610102', '1281521941815042051'],
      ['610103', '1281521941970231297'],
      ['610104', '1281521942062505986'],
      ['610111', '1281521942167363585'],
      ['610112', '1281521942263832577'],
      ['610113', '1281521942389661698'],
      ['610114', '1281521942507102209'],
      ['610115', '1281521942599376898'],
      ['610122', '1281521942725206017'],
      ['610124', '1281521942838452225'],
      ['610118', '1281521942939115521'],
      ['610117', '1281521943064944642'],
      ['610116', '1281521943064944643'],
      ['000002', '1281521952669900806'],
      ['000001', '1281521952669900807'],
      ['610200', '1281521943140442113'],
      ['610202', '1281521943140442114'],
      ['610203', '1281521943253688321'],
      ['610204', '1281521943354351618'],
      ['610222', '1281521943459209217'],
      ['000006', '1281521943459209218'],
      ['610300', '1281521943534706690'],
      ['610302', '1281521943534706691'],
      ['610303', '1281521943656341506'],
      ['610304', '1281521943757004801'],
      ['610322', '1281521943882833922'],
      ['610323', '1281521943979302914'],
      ['610324', '1281521944084160513'],
      ['610326', '1281521944205795330'],
      ['610327', '1281521944298070017'],
      ['610328', '1281521944402927618'],
      ['610329', '1281521944511979522'],
      ['610330', '1281521944612642818'],
      ['610331', '1281521944713306114'],
      ['000005', '1281521952669900808'],
      ['610400', '1281521944780414977'],
      ['610402', '1281521944780414978'],
      ['610404', '1281521945002713089'],
      ['610422', '1281521945120153601'],
      ['610423', '1281521945237594114'],
      ['610424', '1281521945321480194'],
      ['610425', '1281521945409560577'],
      ['610426', '1281521945514418177'],
      ['610482', '1281521945619275778'],
      ['610428', '1281521945715744770'],
      ['610429', '1281521945820602369'],
      ['610430', '1281521945917071361'],
      ['610431', '1281521946013540353'],
      ['610481', '1281521946105815041'],
      ['610500', '1281521946181312514'],
      ['610502', '1281521946181312515'],
      ['610503', '1281521946319724546'],
      ['610522', '1281521946424582146'],
      ['610523', '1281521946533634050'],
      ['610524', '1281521946630103042'],
      ['610525', '1281521946734960642'],
      ['610526', '1281521946839818242'],
      ['610527', '1281521946944675842'],
      ['610528', '1281521947045339138'],
      ['610582', '1281521947255054337'],
      ['610600', '1281521947347329026'],
      ['610602', '1281521947347329027'],
      ['610621', '1281521947481546754'],
      ['610622', '1281521947582210049'],
      ['610623', '1281521947674484737'],
      ['610603', '1281521947779342338'],
      ['610625', '1281521947880005634'],
      ['610626', '1281521947984863233'],
      ['610627', '1281521948081332226'],
      ['610628', '1281521948186189826'],
      ['610629', '1281521948274270209'],
      ['610630', '1281521948374933505'],
      ['610631', '1281521948492374018'],
      ['610632', '1281521948593037313'],
      ['610700', '1281521948664340481'],
      ['610702', '1281521948664340482'],
      ['610703', '1281521948777586690'],
      ['610722', '1281521948882444290'],
      ['610723', '1281521948983107585'],
      ['610724', '1281521949075382274'],
      ['610725', '1281521949180239874'],
      ['610726', '1281521949285097474'],
      ['610727', '1281521949381566466'],
      ['610728', '1281521949482229761'],
      ['610729', '1281521949599670273'],
      ['610730', '1281521949704527873'],
      ['610800', '1281521949767442433'],
      ['610802', '1281521949767442434'],
      ['610881', '1281521949884882946'],
      ['610822', '1281521949985546242'],
      ['610803', '1281521950082015234'],
      ['610824', '1281521950178484225'],
      ['610825', '1281521950274953217'],
      ['610826', '1281521950375616513'],
      ['610827', '1281521950472085505'],
      ['610828', '1281521950572748802'],
      ['610829', '1281521950694383618'],
      ['610830', '1281521950790852609'],
      ['610831', '1281521950887321601'],
      ['611600', '1281521950962819073'],
      ['610902', '1281521950962819074'],
      ['610921', '1281521951080259586'],
      ['610922', '1281521951185117186'],
      ['610923', '1281521951281586178'],
      ['610924', '1281521951382249473'],
      ['610925', '1281521951474524162'],
      ['610926', '1281521951570993154'],
      ['610927', '1281521951688433665'],
      ['610928', '1281521951801679874'],
      ['610929', '1281521951910731777'],
      ['611000', '1281521952003006466'],
      ['611002', '1281521952003006467'],
      ['611021', '1281521952128835586'],
      ['611022', '1281521952246276098'],
      ['611023', '1281521952346939393'],
      ['611024', '1281521952468574210'],
      ['611025', '1281521952560848897'],
      ['611026', '1281521952669900801'],
      ['610900', '1281521952669900802'],
      ['000003', '1281521944906244097'],
      ['611500', '1281521952669900804'],
      ['000004', '1281521947141808129']
  ],
  "success": 1
}

const provinceList = provinceObj.data

const areaClass = Object.create( null );

//设置areaClass的属性不可重写
Object.defineProperties(areaClass, {

  "describe": {
    value: "根据区域代码返回orgId",
    writable: false
  },

  "errText": {
    value: "区域代码不存在",
    writable: false
  },

  "mapBox": {
    value: new Map(provinceList),
    writable: false
  },

  "getOrgId": {
    value: function (item = '') {
      return this.mapBox.has(String(item)) ? this.mapBox.get(String(item)) : this.errText
    }, //注意：此处不可改用箭头函数
    writable: false
  },
  "setOrgId":{
    value:function (item = "") {
      let values = ""
     for (let i = 0;i<provinceObj.data.length;i++){
       if(provinceObj.data[i].slice(',')[1] ===item){
         values = provinceObj.data[i].slice(',')[0]
       }else {
       }
     }
      return values
    },
    writable: false
  },
  "getxingzhengqu":{
    value:function(val) {
      let sheng = val.toString().substr(2, 4)
      let shi = val.toString().substr(4, 2)
      let xian = val.toString().substr(3, 3)
      // 610881000000
      if (sheng === '0000') {
        return "省"
      } else if (shi === "00") {
        return "市"
      } else if (xian !== '000') {
        return "县"
      }
     /* let re = new RegExp("[1-9]")
      let sheng = val.toString().substr(0,3);
      let shi = val.toString().substr(3,1);
      let xian = val.toString().substr(4,2)
      if (re.test(sheng)&&!re.test(shi)&&!re.test(xian)) {
        return "省"
      }else if (!re.test(xian)) {
        return "市"
      }else{
        return "县"
      }*/
    },
  },
  "getRegionCode":{
    value:function(val) {
      let re =new RegExp("[1-9]")
      let sheng = val.toString().substr(0,3);
      let shi = val.toString().substr(3,1);
      let xian = val.toString().substr(4,2)
      if (re.test(sheng)&&!re.test(shi)&&!re.test(xian)) {
        return sheng+"000000000"
      }
      if (!re.test(xian)) {
        return sheng + shi+"00000000"
      }
      return sheng + shi + xian+"000000"
    },
  }
});

// 创建一个继承区域类areaClass的特定地点的区域theAddrArea
let theAddrArea = Object.create( areaClass );

// let areaClass = {
//   describe: '根据区域代码返回orgId',
//   errText: '区域代码不存在',
//   mapBox: new Map(provinceList),
//   getOrgId: function (item = '') {
//     return this.mapBox.has(item) ? this.mapBox.get(item) : this.errText
//   }
// }

export default theAddrArea







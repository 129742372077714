import Vue from 'vue'
import { asyncRoutes, constantRoutes, resetRouter } from '@/router'
import { convertRouter, filterRoutes } from '@/utils/routes'
import { authentication, rolesControl } from '@/config'
import { isArray } from '@/utils/validate'
import { getMenuList } from '@/api/router'

const state = () => ({
  routes: [],
  activeName: ''
})

const getters = {
  routes: (state) => state.routes,
  activeName: (state) => state.activeName
}

const mutations = {
  /**
   * @description 多模式设置路由
   * @param {*} state
   * @param {*} routes
   */
  setRoutes (state, routes) {
    state.routes = routes
  },

  /**
   * @description 修改 activeName
   * @param {*} state
   * @param activeName 当前激活菜单
   */
  changeActiveName (state, activeName) {
    state.activeName = activeName
  }
}

let datafun = (data)=>{
  data.forEach(item =>{
    item.meta['id'] = item.id
    if(item.children.length!==0){
      datafun(item.children)
    }
  })
return data
}
const actions = {
  /**
   * @description 多模式设置路由
   * @param {*} { commit }
   * @param mode
   * @returns
   */
  async setRoutes ({ commit }, mode = 'none') {
    // 默认前端路由
    let routes = [...asyncRoutes]
    // 设置后端路由(不需要可以删除),intelligence(前端导出路由)和all(后端导出路由)
    if (authentication === 'all') {
      const result = await getMenuList()
      const list = datafun(result.data.data)


      window.localStorage.setItem('menuList',JSON.stringify(list))

      if (!isArray(list)) {
        Vue.prototype.$baseMessage('路由格式返回有误！', 'error')
      }
      if (list[list.length - 1].path !== '*') {
        list.push({ path: '*', redirect: '/404', meta: { hidden: true } })
      }
      routes = convertRouter(list)
    }
    // 根据权限和 rolesControl 过滤路由
    const accessRoutes = filterRoutes([...constantRoutes, ...routes], rolesControl)
    // 设置菜单所需路由
    commit('setRoutes', JSON.parse(JSON.stringify(accessRoutes)))
    // 根据可访问路由重置Vue Router
    await resetRouter(accessRoutes)
  }
}

export default { state, getters, mutations, actions }

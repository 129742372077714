<template>
  <el-scrollbar
    class="vab-side-bar side-bar-common"
    :class="{ 'is-collapse': collapse}"
  >
    <!-- :active-text-color="variables['menu-color-active']" -->
    <!-- :active-text-color="variables['menu-color-active']" -->
    <!-- :background-color="variables['menu-background']" -->
    <!-- :text-color="variables['menu-color']" -->
    <!-- :default-openeds="defaultOpeneds" -->
    <el-menu
      active-text-color="#1890ff"
      background-color="#FFFFFF"
      :collapse="collapse"
      :collapse-transition="false"
      :default-active="activeMenu"
      :default-expand-all="true"
      :default-openeds="defaultOpeneds" 
      menu-trigger="click"
      mode="vertical"
      text-color="#515a6e"
      :unique-opened="uniqueOpened"
    >
      <template v-for="(route, index) in handleRoutes">
        <bd-menu
          v-if="route.meta && !route.meta.hidden"
          :key="index + route.name"
          :item="route"
        />
      </template>
    </el-menu>
  </el-scrollbar>
</template>

<script>
import variables from '@/bd/styles/variables/variables.module.scss'
import { mapGetters } from 'vuex'
import { defaultOpeneds, uniqueOpened } from '@/config'
import { handleActivePath, handleMatched } from '@/utils/routes'

export default {
  name: 'SideBar',
  props: {
    layout: {
      type: String,
      default: 'vertical'
    }
  },
  data () {
    return {
      activeMenu: '',
      defaultOpeneds,
      uniqueOpened,
      variables
    }
  },
  computed: {
    ...mapGetters({
      extra: 'settings/extra',
      routes: 'routes/routes',
      collapse: 'settings/collapse',
      activeName: 'routes/activeName'
    }),
    handleRoutes () {
      const sysMenu = this.routes.find(route => {
        return route.meta && route.meta.hidden !== true && route.meta.title === '系统管理'
      })
      return sysMenu && sysMenu.children ? sysMenu.children : []   
    }
  },
  watch: {
    $route: {
      handler (route) {
        this.activeMenu = handleActivePath(route)
      },
      immediate: true
    },
    activeName: {
      handler (val) {
        const matched = handleMatched(this.routes, val)
        this.extra.first = matched[0].name
        this.activeMenu = matched[matched.length - 1].path
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @mixin active {
    &:hover {
      color: $base-color-white;
      // background-color: $base-menu-background-active !important;
      background-color: #e8f4ff !important;
    }

    &.is-active {
      color: $base-color-white;
      // background-color: $base-menu-background-active !important;
      background-color: #e8f4ff !important;
    }
  }

  .vab-side-bar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: $base-z-index + 1;
    width: $base-left-menu-width;
    height: 100vh;
    overflow: hidden;
    // background: $base-menu-background;
    background: #FFFFFF;
    // box-shadow: $base-box-shadow;
    box-shadow: 3px 0px 6px 0px rgb(32 125 255 / 10%);
    transition: $base-transition;

    &.side-bar-common {
      top: $base-top-bar-height;
      height: calc(100vh - #{$base-top-bar-height});
    }

    &.is-collapse {
      width: $base-left-menu-width-min;
      border-right: 0;

      ::v-deep {
        .el-menu--collapse.el-menu {
          > .el-menu-item,
          > .el-submenu {
            text-align: center;

            .el-tag {
              display: none;
            }
          }
        }

        .el-menu-item,
        .el-submenu {
          text-align: left;
        }

        .el-menu--collapse {
          border-right: 0;

          .el-submenu__icon-arrow {
            right: 10px;
            margin-top: -3px;
          }
        }
      }
    }

    ::v-deep {
      .el-scrollbar__wrap {
        overflow-x: hidden;
      }

      .el-menu {
        border: 0;
      }

      .el-menu-item,
      .el-submenu__title {
        height: $base-menu-item-height;
        overflow: hidden;
        line-height: $base-menu-item-height;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: middle;

        i {
          color: inherit;
        }
      }

      .el-menu-item {
        @include active;
      }
    }
  }
</style>

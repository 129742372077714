import Vue from 'vue'
import { baseURL, cloudUrl, webscoket, aiPictureUrl, toolAddressUrl, tiandituUrl, subDomainsUrl, uploadFileUrl } from '@/config'
// 加载雪碧图
import '@/icons'

// // 加载VabIcon
// import VabIcon from 'vab-icons'
// import 'vab-icons/lib/vab-icons.css'

// Vue.component('VabIcon', VabIcon)

import moment from "moment"
Vue.prototype.$moment = moment

// 加载插件
const Plugins = require.context('./plugins', true, /\.js$/)
Plugins.keys().map(Plugins)

// 加载组件
const Components = require.context('.', true, /\.vue$/)
Components.keys()
  .map(Components)
  .forEach((item) => {
    if (item.default.name && item.default.name !== 'LayoutsPage') {
      Vue.component(item.default.name, item.default)
    }
  })

// 加载全局样式样式
import './styles/bd.scss'

// 全局配置请求类相关接口地址，挂载 window.global_url 下
window.global_url = {
  baseURL,
  cloudUrl,
  webscoket,
  aiPictureUrl,
  toolAddressUrl,
  tiandituUrl,
  subDomainsUrl,
  uploadFileUrl
}

<template>
    <div id="leftPlan">
      <slot></slot>
    </div>
</template>

<script>
export default {
  name: 'LeftPlan'
}
</script>

<style lang="scss" scoped>
 #leftPlan{
    width: 390px;
    position: absolute;
    left: 20px;
    top: 20px;
    background-color: #eaedf3;
    box-shadow: 0 2px 20px 0 rgb(0, 0, 0,0.1);
 }
</style>

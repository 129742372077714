<template>
  <div v-if="itemOrMenu.meta && itemOrMenu.meta.levelHidden">
    <template v-for="route in itemOrMenu.children">
      <bd-menu :key="route.path" :item="route" />
    </template>
  </div>
  <div class="fixIcon" v-else-if="itemOrMenu.meta && itemOrMenu.meta.title !== '系统管理'">
    <el-submenu
      ref="subMenu"
      :index="itemOrMenu.path"
      :popper-append-to-body="true"
    >
      <template slot="title">
        <svg-icon
          v-if="itemOrMenu.meta && itemOrMenu.meta.icon"
          :icon-class="itemOrMenu.meta.icon"
          :is-custom-svg="itemOrMenu.meta.isCustomSvg"
        />
        <span :title="itemOrMenu.meta.title">
          {{ itemOrMenu.meta.title }}
        </span>
      </template>
      <slot />
    </el-submenu>
  </div>
</template>

<script>

export default {
  name: 'VabSubmenu',
  props: {
    itemOrMenu: {
      type: Object,
      default () {
        return null
      }
    }
  },
  methods: {

  }
}
</script>


<!-- 可视化大屏 -->
<template>
  <svg-icon class="screen_icon" v-if="isVisualPermiss === '省'" icon-class="visualScreen" @click="visualScreenRoute"></svg-icon>
</template>

<script>
import { mapGetters } from "vuex"
import theAddrArea from '@/utils/province'

export default {
  name: 'BdVisualScreen',
  data () {
    return {
      isVisualPermiss: false
    }
  },
  mounted () {
    this.isVisualPermiss = theAddrArea.getxingzhengqu(theAddrArea.setOrgId(this.orgId))
  },
  computed: {
    ...mapGetters({
      orgId: "user/orgId"
    })
  },
  methods: {
    async visualScreenRoute() {
      window.open("#/LargeDataScreen")
    }
  }
}
</script>

<style lang="scss" scoped>
  .screen_icon {
    color: #FFFFFF;
    cursor: pointer;
  }
</style>